import { Toast, Dialog } from "vant";
import "vant/es/toast/style";
import "vant/es/dialog/style";
// import "vant/es/notify/style";
// import 'vant/es/image-preview/style';
// 五个函数式组件，使用插件无法正确引入，所以函数要全局注册下
//封装更改toast默认值
export const myVanToast = (options) => {
    Toast({
        type: options.type ? options.type : "text",
        message: options.message,
        duration: options.duration >= 0 ? options.duration : 2000,
        position: options.position ? options.position : "middle",
    });
    return Toast;
};
//也可局部注册
// app.component("my-dialog", myDialog.Component);
export const myDialog = () => {
    Dialog.name = "my-van-dialog";
    return Dialog;
};
