import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { downloadApp } from "@/utils";
export default {
  props: ["anchorIndex"],
  computed: { ...mapState(["lang"])
  },
  components: {},

  data() {
    return {
      downloadApp: downloadApp,
      index: 0,
      drawer: false
    };
  },

  created() {},

  mounted() {
    window.addEventListener("scroll", this.getScroll);
  },

  methods: {
    getScroll() {
      var scroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (scroll >= 375) {
        document.getElementsByClassName("download")[0].style.display = "flex";
      } else {
        document.getElementsByClassName("download")[0].style.display = "none";
      }
    },

    navIndex(index) {
      if (index == 7) {
        this.$router.push("/news");
      } else if (this.$route.path == "/news" || this.$route.path == "/news-detail") {
        this.$router.push("/home");
        this.getChildValue();
      }

      this.index = index;
    },

    setLang(lang) {
      if (lang !== this.lang) {
        this.$i18n.locale = lang;
        this.$store.commit("updateLang", lang);
      }
    },

    getChildValue() {
      this.$emit("changeIndex", this.index);
    },

    showAside() {
      this.drawer = !this.drawer;
      this.$refs.drawer.getDrawer({
        type: this.drawer
      });
    },

    closeAside(type) {
      this.drawer = type; // this.$refs.drawer.getDrawer({
      //     type: true,
      // });
    }

  },
  watch: {
    // anchorIndex(n) {
    //     console.log("chufa");
    //     this.index = n;
    //     console.log(this.index, "父组件传过来的");
    // },
    anchorIndex: {
      handler(n) {
        this.index = n;
      },

      immediate: true // 在组件创建时立即执行一次handler

    }
  }
};