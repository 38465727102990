import { createI18n } from "vue-i18n";
import store from "../store";
if (store.state.lang == "zh-CN") {
    store.commit("updateLang", "zh-CN");
} else if (store.state.lang == "zh-TW") {
    store.commit("updateLang", "zh-TW");
} else {
    store.commit("updateLang", "en-US");
}
const i18n = createI18n({
    legacy: false, // 使用CompotitionAPI必须添加这条.
    locale: store.state.lang || "zh-CN",
    fallbackLocale: store.state.lang || "zh-CN",
    messages: {
        "en-US": require("../locals/en.js").default,
        "zh-CN": require("../locals/zh.js").default,
        "zh-TW": require("../locals/zht.js").default,
    },
});
export default i18n;
