export default {
    header: {
        text1: "白皮书",
        text2: "产品路线图",
        text3: "代币说明",
        text4: "关于我们",
        text5: "立即下载",
        text6: "新闻与媒体",
        text7: "首页",
        text8: "EEG协议",
        text9: "BIG绿皮书",
    },
    home: {
        text1: "开启全新Web3.0运动生活每天运动赚！",
        text2: "这一次",
        text3: "Web3终于走进了人们的生活！",
        text4: "打造全球首款Web3.0真正服务现实生活需求的海量用户级平台！",
        text5: "2023年4月1日，Being APP上线，主打Web3运动生活方式，帮助用户随时随地锻炼健身还能赚钱。",
        text6: "运动，贵在坚持！用Being有NFT奖励很容易养成坚持运动的好习惯。",
        text7: "引领平时不运动的人开始Web3方式简单运动起来，这才是最大的市场。不爱运动的人远超经常运动的人群。Being引领不爱运动的人简单运动，让人有改变，变得更好。",
        text8: "Being秉承先用户，先产品，先需求的发展理念，用Web3的方式真正解决现实生活中用户的真实需求！平台做到足够大的用户体量后才会考虑代币的上线。",
        text9: "当前规划为代币将在Being App百万日活跃目标达成时，按照产品路线图的成长目标预计2年内启动唯一一轮的代币Launchpad销售并上线代币的交易流通。敬请随时关注我们的官网和Twitter通知。",
        text9_1:
            "当前$BEING代币仅面向Web3机构投资者和知名投资人进行私募融资。$BEING代币在启动Launchpad时才会正式上线，私募投资者也只有在$BEING代币上线后才会获得解锁的额度，当前没有任何$BEING代币实际发行，特此告知。",
        text10: "团队和顾问",
        text11: "即将上线！",
        text12: "即将发布！",
        text13: "关注我们",
        text14: "我们的支持者",
        text15: "联系我们",
        text16: "暂无内容",
    },
    wx: {
        text1: "打开浏览器下载",
    },
};
