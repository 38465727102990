import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import "./plugins/adaptive";
import "./plugins/elements";
import "./styles/common.less";
import "./styles/vant.less";
import { isH5 } from "./utils";
import api from "./service/api";
import { myDialog } from "./plugins/vant";

const app = createApp(App);
app.config.globalProperties.$api = api;
let h = 0;
if (isH5()) {
    h = 50;
} else {
    h = 70;
}

app.directive("anchor", (el, binding) => {
    var a = location.href;
    var b = process.env.NODE_ENV === "production" ? (a.indexOf("/home") > -1 ? "/home" : "undefined") : a.split("#")[1];
    if (b == "" || b == "/home") {
        el.onclick = function () {
            // document.documentElement.scrollTop = document.getElementById("anchor-" + binding.value).offsetTop - h;
            $(document.documentElement).animate(
                {
                    scrollTop: $("#anchor-" + binding.value).offset().top - h,
                },
                {
                    duration: 1000,
                    easing: "swing",
                }
            );
        };
    }
});
// app.use(VMdEditor);
app.use(myDialog());
app.use(store).use(router).use(i18n).mount("#app");
app.provide("$api", api);
