const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        name: "home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Home"),
    },
    {
        path: "/news",
        name: "news",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/News"),
    },
    {
        path: "/news-detail",
        name: "news-detail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Detail"),
    },
];

export default routes;
