import { mapState } from "vuex";
export default {
  name: "index",

  data() {
    return {
      drawer: false
    };
  },

  computed: { ...mapState(["lang"])
  },

  created() {},

  mounted() {},

  methods: {
    getDrawer(item) {
      this.drawer = item.type;
    },

    getLang(lang) {
      if (lang != this.lang) {
        this.$store.commit("updateLang", lang);
        this.$i18n.locale = lang; // 通过切换locale的值来实现语言切换

        this.drawer = false;
        this.$emit("closeAside", this.drawer);
      }
    },

    closeDrawer() {
      this.drawer = false;
      this.$emit("closeAside", this.drawer);
    },

    isNavigate(navigate, path) {
      this.drawer = false;
      this.$emit("closeAside", this.drawer);
      navigate(path);
    }

  },
  props: [],
  watch: {// drawer: {
    // 	return this.drawer = this.drawer
    // }
  },
  components: {}
};