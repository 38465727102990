export default {
    header: {
        text1: "白皮書",
        text2: "產品路線圖",
        text3: "代幣說明",
        text4: "關於我們",
        text5: "立即下載",
        text6: "新聞與媒體",
        text7: "首頁",
        text8: "EEG協議",
        text9: "BIG綠皮書",
    },
    home: {
        text1: "開啟全新Web3.0運動生活每天運動賺！",
        text2: "這一次",
        text3: "Web3終於走進了人們的生活！",
        text4: "打造全球首款Web3.0真正服務現實生活需求的海量用戶級平台！",
        text5: "2023年4月1日，Being APP上線，主打Web3運動生活方式，幫助用戶隨時隨地鍛煉健身還能賺錢。",
        text6: "運動，貴在堅持！用Being有NFT獎勵很容易養成堅持運動的好習慣。",
        text7: "引領平時不運動的人開始Web3方式簡單運動起來，這才是最大的市場。不愛運動的人遠超經常運動的人群。Being引領不愛運動的人簡單運動，讓人有改變，變得更好。",
        text8: "Being秉承先用戶，先產品，先需求的發展理念，用Web3的方式真正解決現實生活中用戶的真實需求！平台做到足夠大的用戶體量後才會考慮代幣的上線。",
        text9: "當前規劃為代幣將在Being App百萬日活躍目標達成時，按照產品路線圖的成長目標預計2年內啟動唯一一輪的代幣Launchpad銷售並上線代幣的交易流通。敬請隨時關注我們的官網和Twitter通知。",
        text9_1:
            "當前$BEING代幣僅面向Web3機構投資者和知名投資人進行私募融資。$BEING代幣在啟動Launchpad時才會正式上線，私募投資者也只有在$BEING代幣上線後才會獲得解鎖的額度，當前沒有任何$BEING代幣實際發行，特此告知。",
        text10: "團隊和顧問",
        text11: "即將上線！",
        text12: "即將發布！",
        text13: "關注我們",
        text14: "我們的支持者",
        text15: "聯繫我們",
        text16: "暫無內容",
    },
    wx: {
        text1: "打開瀏覽器下載",
    },
};
