import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
//禁止在生产环境使用严格模式
const debug = process.env.NODE_ENV !== "production";
const state = {
    lang: "zh-CN"
};

const mutations = {
    updateLang(state, value) {
        state.lang = value;
    }
};

const actions = {
    
};
export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});
