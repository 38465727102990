// import i18n from "../plugins/i18n.js";
// import { ElMessage } from "element-plus";

export const isH5 = () => {
    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsUcBrowser = sUserAgent.match(/ucbrowser/i) == "ucbrowser";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || bIsUcBrowser) {
        return true;
    } else {
        return false;
    }
};

export const isWx = () => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
};
function add0(m) {
    return m < 10 ? "0" + m : m;
}
export const format = (time) => {
    //shijianchuo是整数，否则要parseInt转换
    if (time == "" || time == 0) {
        return "...";
    } else {
        var timer = new Date(time * 1000);
        var y = timer.getFullYear();
        var m = timer.getMonth() + 1;
        var d = timer.getDate();
        return y + "." + add0(m) + "." + add0(d) + " ";
    }
};
export const downloadApp = (type) => {
    // ElMessage({
    //     message: type === "whitepaper" ? i18n.global.t("home.text12") : i18n.global.t("home.text11"),
    //     type: "warning",
    //     grouping: true,
    //     duration: 2000,
    // });
    // return false;
    if (type === "ios") {
        window.location.href = "https://nft.being.com/.well-known/apple-app-site-association";
        setTimeout(() => {
            window.location.href = "https://apps.apple.com/app/id6446310298";
        }, 1000);
    }
    if (type === "android") {
        window.location.href = "web3://being.com"; //android app协议
    }
};
//获取地址栏参数
export function getRequest() {
    var a = decodeURI(location.href); //获取url中"?"符后的字串
    var url = "?" + a.split("?")[1];
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = [];
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}
