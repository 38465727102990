export default {
    header: {
        text1: "Whitepaper",
        text2: "Roadmap",
        text3: "Tokenomics",
        text4: "About us",
        text5: "Download",
        text6: "News & Press",
        text7: "Home",
        text8: "EEG protocol",
        text9: "BIG green paper",
    },
    home: {
        text1: "Start a new Web3.0 sports life and move to earn every day!",
        text2: "This time",
        text3: "Web3 finally entered people's lives!",
        text4: "Build the world's first platform with massive users that truly serves the needs of real life with Web3.0!",
        text5: "On April 1, 2023, the Being APP will be launched, focusing on the Web3 sports lifestyle, and enabling users exercise anytime while making money.",
        text6: "Exercise is about perseverance! With NFT rewarding mechanics of Being APP, it will be much easier to develop a good habit of persevering in exercising.",
        text7: "Leading people who don't usually exercise to start doing sports in the Web3 way, and this is the biggest market. People who do not like to exercise far outnumber those who exercise regularly. Being leads people who don't like sports to simply exercise, making people change and become better.",
        text8: "Being adheres to the development concept of users first, products first, and needs first, and uses Web3 to truly solve the real needs of users in real life! The launch of tokens will not be considered until the platform achieves a large enough user volume.",
        text9: "The current plan is to launch a single round of token Launchpad sale and enable token trading within 2 years after achieving the goal of one million daily active users on the Being App, according to the growth goals on the product roadmap. Please stay tuned to our official website and Twitter for updates.",
        text9_1:
            "Currently, the $BEING token is only available for private fundraising for Web3 institutions and well-known investors. The $BEING token will only be officially launched when the Launchpad is initiated, and private investors will only receive unlocked quotas after the $BEING token is launched. There are currently no actual $BEING tokens issued, and this is to inform you of this.",
        text10: "Team and Advisors",
        text11: "Coming soon!",
        text12: "Coming soon!",
        text13: "Follow us",
        text14: "Our Backers",
        text15: "Contact us",
        text16: "No content yet",
    },
    wx: {
        text1: "Open the browser to download",
    },
};
